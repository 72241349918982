import '../../styles/contact.scss';
import { Form } from '../../components/contact/Form';
import globe from '../../assets/whatsapp.svg';

export const Contact = () => {
      return (
        <div className='contact'>
            <div id="contacto"></div>
          <div className='inner-contact'>
            <div className='form-container'>
               <h2>Contacto</h2>
              <Form />
            </div>
            <div className='contact-data'>
              <div className='data-contaimer'>
                <div className='data'>
                  <h3>Dirección:</h3>
                  <p>Dr. Ignacio Arieta 3875</p>
                  <p>B1754AQQ San Justo, Buenos Aires</p>
                </div>
                <div className='data'>
                  <h3>Teléfonos:</h3>
                    <p><a href="tel:46511491">4651-1491</a></p>
                    <p><a href="tel:44418001">4441-8001 / 8975</a></p>
                    <p><a href="tel:44823886">4482-3886</a></p>
                    <p><a href="https://wa.me/1131566492" target="_blank" rel="noopener noreferrer">11 3156 6492</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
};
